export default{
    namespaced: true,
    state:{
        brand:'',
        categoryCommunity:'',
        year:'',
        dso:'',
    },
    mutations:{
        setdso :(state,payload) => {
            state.dso = payload
            },
        setbrand :(state,payload) => {
            state.brand = payload
            },
        setcategory :(state,payload) => {
            state.categoryCommunity = payload
            },
        setyear :(state,payload) => {
            state.year = payload
            },
    },
    actions:{
        setdso:({commit},payload)=>{
            commit('setdso',payload)
        },
        setbrand:({commit},payload)=>{
            commit('setbrand',payload)
        },
        setcategory:({commit},payload)=>{
            commit('setcategory',payload)
        },
        setyear:({commit},payload)=>{
            commit('setyear',payload)
        },
    },
    getters:{
        dso : state => state.dso,
        brand : state => state.brand,
        categoryCommunity : state => state.categoryCommunity,
        year : state => state.year,
    },
    
}