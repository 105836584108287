<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="10">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
      <v-col md="2" sm="12">
        <v-btn
          v-if="getRole.role.insert"
          class="float-right"
          @click="inputExcel"
          :loading="isLoadingDownload"
          depressed
          color="success"
          block
        >
          Input Excel
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-alert text type="info"> Maksimal File adalah 2 MB </v-alert>
              <v-card>
                <v-card-title>
                  <p>Unggah Logo</p>
                </v-card-title>
                <v-card-text v-if="form.logoTemp.length > 0">
                  <v-btn depressed color="error" @click="deleteImageUpload"
                    >Batal</v-btn
                  >
                </v-card-text>
                <v-card-text v-else>
                  <input
                    @change="changeMedia"
                    ref="myFileInput"
                    type="file"
                    accept="image/*"
                  />
                </v-card-text>
                <v-img :src="logoDisplay" v-if="thereIsImage"></v-img>
              </v-card>

              <v-alert text type="info" class="mt-5">
                Informasi Sosial Media
                <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>
                <p class="grey--text">
                  Tekan enter jika akan menambahkan banyak sosial media setiap
                  selesai ketik
                </p>
              </v-alert>
              <multiselect
                v-model="tag.value"
                tag-placeholder="Tambah Link Sosial Media"
                placeholder="Cari atau tambah link sosial media"
                :options="tag.options"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
              ></multiselect>
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>
              <v-autocomplete
                v-model="form.pic"
                :items="pics"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="PIC"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-autocomplete
                v-model="form.dso"
                :items="dsos"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="DSO"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-autocomplete
                v-model="form.brands"
                :items="brands"
                item-value="id"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama Brand"
                required
                outlined
                dense
              >
              <template slot="selection" slot-scope="data">
                <!-- HTML that describe how select should render selected items -->
                {{ data.item.name }}
              </template>
              <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                {{ data.item.name }}
              </template>
              </v-autocomplete>

              <v-text-field
                v-model="form.name"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama Komunitas"
                required
                outlined
                dense
              ></v-text-field>

              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="form.dob"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.dob"
                    label="Tanggal Lahir Komunitas"
                    :rules="[(v) => !!v || 'Kolom wajib diisi']"
                    v-bind="attrs"
                    readonly
                    v-on="on"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.dob" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(form.dob)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-textarea
                v-model="form.description"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Deskripsi"
                required
                outlined
                dense
              ></v-textarea>
              <!-- :items="categories" -->
              <v-autocomplete
                v-model="form.category"
                :items="categories"
                item-text="key"
                item-value="value"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Kategori"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="form.joinedAt"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.joinedAt"
                    label="Tanggal Join Komunitas"
                    :rules="[(v) => !!v || 'Kolom wajib diisi']"
                    v-bind="attrs"
                    readonly
                    v-on="on"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.joinedAt" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu2.save(form.joinedAt)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-text-field
                v-model="form.totalMember"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Jumlah Member"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="form.totalActiveMember"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Jumlah Member Aktif"
                required
                outlined
                dense
              ></v-text-field>


              <v-text-field
                v-model="form.totalSmoker"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Jumlah Perokok"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="form.totalVillagers"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Jumlah Warga Desa"
                required
                outlined
                dense
                v-if="form.category === 'heppiii'"
              ></v-text-field>

              <!-- <v-text-field
                v-model="form.rationalConsumptionTarget"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Target Konsumsi Rasional"
                required
                outlined
                dense
                v-if="form.category === 'heppiii'"
              ></v-text-field> -->
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Alamat</v-alert>
              <v-autocomplete
                v-model="form.address.province"
                :items="provinces"
                item-text="provinceName"
                item-value="provinceId"
                label="Provinsi"
                required
                outlined
                dense
                @change="getDistrict($event)"
              ></v-autocomplete>

              <v-autocomplete
                v-model="form.address.district"
                :items="districts"
                item-text="districtName"
                item-value="districtId"
                label="Kabupaten/Kota"
                required
                outlined
                dense
                @change="getSubDistrict($event)"
              ></v-autocomplete>

              <v-autocomplete
                v-model="form.address.subDistrict"
                :items="subDistricts"
                item-text="subDistrictName"
                item-value="subDistrictId"
                label="Kecamatan"
                required
                outlined
                dense
                @change="getVillages($event)"
              ></v-autocomplete>

              <v-autocomplete
                v-model="form.address.village"
                :items="villages"
                item-text="villageName"
                item-value="villageId"
                label="Kelurahan/Desa"
                required
                outlined
                dense
                @change="getVillagesValue($event)"
              ></v-autocomplete>

              <v-text-field
                v-model="form.address.street"
                label="Jalan"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="form.address.zipCode"
                :rules="[
                  (v) => Number.isInteger(Number(v)) || 'Isian harus angka'
                ]"
                label="Kode Pos"
                :maxlength="5"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4 float-right"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error Brands -->
    <Alert
      :status="isErrorGetBrands && errorCodeGetBrands !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetBrands"
    />

    <!-- Error Categories -->
    <Alert
      :status="isErrorGetCategories && errorCodeGetCategories !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetCategories"
    />

    <!-- Error Users -->
    <Alert
      :status="isErrorGetUsers && errorCodeGetUsers !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetUsers"
    />

    <!-- Error Provinces -->
    <Alert
      :status="isErrorGetProvinces && errorCodeGetProvinces !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetProvinces"
    />

    <!-- Error Districts -->
    <Alert
      :status="isErrorGetDistricts && errorCodeGetDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetDistricts"
    />

    <!-- Error Sub Districts -->
    <Alert
      :status="isErrorGetSubDistricts && errorCodeGetSubDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetSubDistricts"
    />

    <!-- Error Villages -->
    <Alert
      :status="isErrorGetVillages && errorCodeGetVillages !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetVillages"
    />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { UserControllers } from "../../controllers/UserControllers.js";
import { AreaControllers } from "../../controllers/AreaControllers.js";
import { CommunityControllers } from "../../controllers/CommunityControllers.js";
import { CategoryControllers } from "../../controllers/CategoryControllers.js";
import { BrandControllers } from "../../controllers/BrandControllers.js";
import { MediaControllers } from "../../controllers/MediaControllers.js";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  name: "create-user",
  data: () => ({
    user: new UserControllers(),
    area: new AreaControllers(),
    community: new CommunityControllers(),
    category: new CategoryControllers(),
    brand: new BrandControllers(),
    media: new MediaControllers(),
    valid: true,
    title: "Buat Komunitas",

    isLoading: false,
    fullPage: true,

    form: {
      logo: "",
      pic: null,
      dso: null,
      brands: "",
      name: "",
      dob: "",
      description: "",
      category: "",
      joinedAt: "",
      area: "",
      address: {
        province: null,
        district: null,
        subDistrict: null,
        village: null,
        street: "",
        zipCode: "",
      },
      logoTemp: [],
      totalMember: null,
      totalSmoker: null,
      totalActiveMember: null,
      totalVillagers: null,
      rationalConsumptionTarget: null,
    },

    menu: false,
    menu2: false,

    addressFiltered: {
      province: "",
      district: "",
      subDistrict: "",
      village: "",
    },

    selectScope: {
      page: 1,
      limit: 1000,
    },

    tag: {
      value: [],
      options: [],
    },
    logoDisplay: null,
    thereIsImage: false,
  }),

  components: {
    Loading,
    Alert,
    Multiselect,
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    // media
    medias() {
      return this.media.successMsg;
    },

    isErrorUploadMedia() {
      return this.media.isError;
    },

    errorCauseMediaUpload() {
      return this.media.errorCause;
    },

    isLoadingUploadMedia() {
      return this.media.isLoading;
    },

    // community
    isErrorCreateCommunity() {
      return this.community.isError;
    },

    errorCauseCreateCommunity() {
      return this.community.errorCause;
    },

    isLoadingCreateCommunity() {
      return this.community.isLoading;
    },

    // categories
    categories() {
      if(this.form.brands){
        return this.category.categoryCommunityList.filter(category=>category.brand.includes(this.form.brands))
      }
      return this.category.categoryCommunityList;
    },

    errorCodeGetCategories() {
      return this.category.errorCode;
    },

    isErrorGetCategories() {
      return this.category.isError;
    },

    errorCauseGetCategories() {
      return this.category.errorCause;
    },

    // brands
    brands() {
      return this.brand.brandList.filter(brand=>brand.product=='Rokok');
    },

    errorCodeGetBrands() {
      return this.brand.errorCode;
    },

    isErrorGetBrands() {
      return this.brand.isError;
    },

    errorCauseGetBrands() {
      return this.brand.errorCause;
    },

    // users
    pics() {
      return this.user.userList.filter((item) => item.role === "user.pic");
    },

    dsos() {
      return this.user.userListAM;
    },

    errorCodeGetUsers() {
      return this.user.errorCode;
    },

    isErrorGetUsers() {
      return this.user.isError;
    },

    errorCauseGetUsers() {
      return this.user.errorCause;
    },

    // provinces
    provinces() {
      return this.area.provinceList;
    },

    errorCodeGetProvinces() {
      return this.area.errorCodeProvince;
    },

    isErrorGetProvinces() {
      return this.area.isErrorProvince;
    },

    errorCauseGetProvinces() {
      return this.area.errorCauseProvince;
    },

    // districts
    districts() {
      return this.area.districtList;
    },

    errorCodeGetDistricts() {
      return this.area.errorCodeDistrict;
    },

    isErrorGetDistricts() {
      return this.area.isErrorDistrict;
    },

    errorCauseGetDistricts() {
      return this.area.errorCauseDistrict;
    },

    // sub districts
    subDistricts() {
      return this.area.subDistrictList;
    },

    errorCodeGetSubDistricts() {
      return this.area.errorCodeSubDistrict;
    },

    isErrorGetSubDistricts() {
      return this.area.isErrorSubDistrict;
    },

    errorCauseGetSubDistricts() {
      return this.area.errorCauseSubDistrict;
    },

    // village
    villages() {
      return this.area.villageList;
    },

    errorCodeGetVillages() {
      return this.area.errorCodeVillage;
    },

    isErrorGetVillages() {
      return this.area.isErrorVillage;
    },

    errorCauseGetVillages() {
      return this.area.errorCauseVillage;
    },
  },

  created() {
    this.getUsers();
    this.getDsos();
    this.getProvinces();
    this.getBrands();
    this.getCategories();
  },

  methods: {
    back() {
      this.$router.go(-1);
    },

    addTag(newTag) {
      this.tag.options.push(newTag);
      this.tag.value.push(newTag);
    },

    inputExcel() {
      this.$router.push({
        name: "Insert Community",
      });
    },

    getUsers() {
      return this.user.getUserList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getDsos(){
      return this.user.getUserAMList('admin.dso', 1, 1000, '')
    },

    getBrands() {
      return this.brand.getBrandList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getCategories() {
      return this.category.getcategoryCommunityList();
    },

    getProvinces() {
      return this.area.getProvinceList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getDistrict(provinceId) {
      const { provinceName } = this.provinces.find(
        (item) => item.provinceId === provinceId
      );
      this.addressFiltered.province = provinceName;
      return this.area.getDistrictList(
        provinceId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getSubDistrict(districtId) {
      const { districtName } = this.districts.find(
        (item) => item.districtId === districtId
      );
      this.addressFiltered.district = districtName;
      return this.area.getSubDistrictList(
        districtId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getVillages(subDistrictId) {
      const { subDistrictName } = this.subDistricts.find(
        (item) => item.subDistrictId === subDistrictId
      );
      this.addressFiltered.subDistrict = subDistrictName;
      return this.area.getVillageList(
        subDistrictId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getVillagesValue(villageId) {
      const { villageName, postalCode } = this.villages.find(
        (item) => item.villageId === villageId
      );
      this.addressFiltered.village = villageName;
      this.form.address.zipCode = postalCode;
    },

    changeMedia(e) {
      const selectImage = e.target.files[0];
      this.createBase64Image(selectImage);
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      const sizeLimit = fileObject.size / 1000;
      if (sizeLimit > 2000) {
        Swal.fire({
          icon: "error",
          title: "KESALAHAN",
          text: "File tidak boleh lebih dari 2 MB",
        });
        this.$refs.myFileInput.value = "";
      } else {
        reader.readAsDataURL(fileObject);

        reader.onload = async (e) => {
          this.thereIsImage = true;
          this.logoDisplay = e.target.result;
          this.form.logoTemp = new Array(e.target.result);
        };
      }
    },

    deleteImageUpload() {
      this.form.logoTemp.splice(0, 1);
      this.logoDisplay = null;
      this.thereIsImage = false;
    },

    async uploadMedia(type, image) {
      return this.media.uploadMedia(type, image);
    },

    async submitProcess(
      pic,
      dso,
      brandId,
      name,
      dob,
      description,
      category,
      joinedAt,
      province,
      district,
      subDistrict,
      village,
      street,
      zipCode,
      logo,
      socialMedias,
      totalMember,
      totalSmoker,
      totalActiveMember,
      totalVillagers,
      rationalConsumptionTarget
    ) {
      return this.community.createCommunityList(
        pic,
        dso,
        brandId,
        name,
        dob,
        description,
        category,
        joinedAt,
        province,
        district,
        subDistrict,
        village,
        street,
        zipCode,
        logo,
        socialMedias,
        Number(totalMember),
        Number(totalSmoker),
        Number(totalActiveMember),
        Number(totalVillagers),
        Number(rationalConsumptionTarget)
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();

      if (this.thereIsImage) {
        this.isLoading = true;
        await this.uploadMedia("community", this.form.logoTemp);

        if (this.isErrorUploadMedia) {
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseMediaUpload,
          });
          this.isLoading = false;
          this.isErrorUploadMedia = false;
        } else {
          this.isLoading = false;
          this.form.logo = this.medias[0];
        }
      }

      if (validate && !this.isErrorUploadMedia) {
        this.isLoading = true;
        await this.submitProcess(
          this.form.pic,
          this.form.dso,
          this.form.brands,
          this.form.name,
          this.form.dob,
          this.form.description,
          this.form.category,
          this.form.joinedAt,
          this.addressFiltered.province,
          this.addressFiltered.district,
          this.addressFiltered.subDistrict,
          this.addressFiltered.village,
          this.form.address.street,
          this.form.address.zipCode,
          this.form.logo,
          this.tag.value,
          this.form.totalMember,
          this.form.totalSmoker,
          this.form.totalActiveMember,
          this.form.totalVillagers,
          this.form.rationalConsumptionTarget
        );

        if (this.isErrorCreateCommunity) {
          this.isLoading = false;
          this.isErrorCreateCommunity = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateCommunity,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.tag.value = [];
          this.tag.options = [];
          this.isErrorCreateCommunity = false;
          this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>