import axios from "axios";
import { EventList, CreateEventList, DeleteEvent, UpdateEvent, EventDetail, getTotalEvent } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
  Authorization: `Bearer ${getToken()}`,
};
export const EventListRequest = (page, limit, keyword, brand, category, dsoId, group) => {
  const resp = axios({
    method: "get",
    url: `${BaseUrl()}${EventList(page, limit, keyword, brand, category, dsoId, group)}`,
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};

export const CreateEventListRequest = (title, dsoId, brandId, collabCommunity, drpId,
  venueId, invitedCommunity, description, category, date, province, district, subDistrict, village, street, zipCode, locationLink, images, group, scale, method) => {
    console.log({
      title: title,
      brandId: brandId,
      collabCommunity: collabCommunity,
      drpId: drpId,
      venueId: venueId,
      invitedCommunity: invitedCommunity,
      dsoId: dsoId,
      description: description,
      category: category,
      date: date,
      location: {
        province: province,
        district: district,
        subDistrict: subDistrict,
        village: village,
        street: street,
        zipCode: zipCode,
      },
      locationLink: locationLink,
      images: images ? [images] : [],
      group: group,
      scale: scale,
      method: method,
    });
  const resp = axios({
    method: "post",
    url: `${BaseUrl()}${CreateEventList()}`,
    data: {
      title: title,
      brandId: brandId,
      collabCommunity: collabCommunity,
      drpId: drpId,
      venueId: venueId,
      invitedCommunity: invitedCommunity,
      dsoId: dsoId,
      description: description,
      category: category,
      date: date,
      location: {
        province: province,
        district: district,
        subDistrict: subDistrict,
        village: village,
        street: street,
        zipCode: zipCode,
      },
      locationLink: locationLink,
      images: images ? [images] : [],
      group: group,
      scale: scale,
      method: method,
    },
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};

export const DeleteEventRequest = (id) => {
  const resp = axios({
    method: "delete",
    url: `${BaseUrl()}${DeleteEvent(id)}`,
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};

export const UpdateEventRequest = (id, title, dsoId, collabCommunity, drpId,
  venueId, invitedCommunity, brandId, description, category, date, province, district, subDistrict, village, street, zipCode, locationLink, scale, method, group) => {
  const resp = axios({
    method: "put",
    url: `${BaseUrl()}${UpdateEvent(id)}`,
    data: {
      title: title,
      dsoId: dsoId,
      collabCommunity: collabCommunity,
      drpId: drpId,
      venueId: venueId,
      scale: scale,
      invitedCommunity: invitedCommunity,
      brandId: brandId,
      description: description,
      category: category,
      date: date,
      location: {
        province: province,
        district: district,
        subDistrict: subDistrict,
        village: village,
        street: street,
        zipCode: zipCode,
      },
      locationLink: locationLink,
      method: method,
      group: group
    },
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};

export const EventDetailRequest = (id) => {
  const resp = axios({
    method: "get",
    url: `${BaseUrl()}${EventDetail(id)}`,
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};

export const getTotalEventRequest = (dsoId, year) => {
  const resp = axios({
    method: "get",
    url: `${BaseUrl()}${getTotalEvent(dsoId, year)}`,
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};
