import {
  CreateEventReviewRequest,
  DeleteEventReviewRequest,
  UpdateEventReviewRequest,
  EventReviewDetailRequest
} from "../request/EventReviewRequest.js";

export class EventReviewControllers {
  eventreviewDetail = new Object();
  isError = false;
  isLoading = false;
  errorCause = "";
  errorCode = 0;

  isErrorDelete = false;
  errorCauseDelete = "";
  messageDelete = "";

  constructor() { }

  getEventReviewDetail(id) {
    this.setLoading(true);
    const resp = EventReviewDetailRequest(id)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setEventReviewDetail(response.data.data);
      }).catch(err => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
      }).finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  setEventReviewDetail(data) {
    this.eventreviewDetail = data;
  }

  // getDetail(id) {
  //     this.eventreviewDetail = this.eventreview.find(item => {
  //         return item.id === id
  //     });
  // }

  setLoading(status) {
    this.isLoading = status;
  }

  setError(status) {
    this.isError = status;
  }

  setErrorCause(err) {
    this.errorCause = err;
  }

  setErrorcode(code) {
    this.errorCode = code;
  }


  createEventReview(
    eventId,
    totalParticipant,
    collaborateWithCommunity,
    collaborateWithVanue,
    collaborateWithDRP,
    brandId_smoke,
    sku_smoke,
    total_smoke,
    brandId_nonSmoke,
    product_nonSmoke,
    total_nonSmoke,
    images,
    group,
    brandSupport,
    brandSupportDescription,
    dsoParticipation,
    otherDsoParticipation
    // images_smoke,
    // images_nonSmoke
  ) {
    this.setLoading(true);
    const resp = CreateEventReviewRequest(
      eventId,
      totalParticipant,
      collaborateWithCommunity,
      collaborateWithVanue,
      collaborateWithDRP,
      brandId_smoke,
      sku_smoke,
      total_smoke,
      brandId_nonSmoke,
      product_nonSmoke,
      total_nonSmoke,
      images,
      group,
      brandSupport,
      brandSupportDescription,
      dsoParticipation,
      otherDsoParticipation
      // images_smoke,
      // images_nonSmoke
    )
      .then(() => {
        this.setError(false);
      }).catch(err => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
      }).finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  deleteEventReview(id) {
    this.setLoading(true);
    const resp = DeleteEventReviewRequest(id)
      .then((response) => {
        this.setErrorDelete(false);
        this.setMsgDelete(response.data.message);
      }).catch(err => {
        this.setErrorDelete(true);
        this.setErrorCauseDelete(err.response.data.message);
      }).finally(() => {
        this.setLoading(false);
      });

    return resp;
  }



  setMsgDelete(value) {
    this.messageDelete = value;
  }

  setErrorDelete(status) {
    this.isErrorDelete = status;
  }

  setErrorCauseDelete(err) {
    this.errorCauseDelete = err;
  }

  updateEventReview(
    id,
    eventId,
    totalParticipant,
    collaborateWithCommunity,
    collaborateWithVanue,
    collaborateWithDRP,
    brandId_smoke,
    sku_smoke,
    total_smoke,
    brandId_nonSmoke,
    product_nonSmoke,
    total_nonSmoke,
    images,
    group,
    brandSupport,
    brandSupportDescription,
    dsoParticipation,
    otherDsoParticipation
    // images_smoke,
    // images_nonSmoke
  ) {
    this.setLoading(true);
    const resp = UpdateEventReviewRequest(
      id,
      eventId,
      totalParticipant,
      collaborateWithCommunity,
      collaborateWithVanue,
      collaborateWithDRP,
      brandId_smoke,
      sku_smoke,
      total_smoke,
      brandId_nonSmoke,
      product_nonSmoke,
      total_nonSmoke,
      images,
      group,
      brandSupport,
      brandSupportDescription,
      dsoParticipation,
      otherDsoParticipation
      // images_smoke,
      // images_nonSmoke
    )
      .then(() => {
        this.setError(false);
      }).catch(err => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
      }).finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

}