import Vue from 'vue'
import Vuex from 'vuex'
import { AreaManagerControllers } from "../controllers/AreaManagerControllers.js";
import { BrandControllers } from "../controllers/BrandControllers.js";
import { titleCase } from "../external/functions/Utilization.js";
import targetKPI from './target-kpi'
import setMedfol from './set-medfol'
import setMedsos from './set-medsos-kpi'
import setDso from './set-dso'
import setCommunityFilter from './set-community-filter'
import setConsumptionFilter from './set-consumption-filter'


Vue.use(Vuex)

const am = new AreaManagerControllers();
const brand = new BrandControllers();
export const store = new Vuex.Store({
  modules:{
    targetKPI,
    setMedfol,
    setMedsos,
    setDso,
    setCommunityFilter,
    setConsumptionFilter
  },
  state: {
    role: null,
    areaManagers: [],
    areaDSO: [],
    brands:[]
  },
  getters: {
    getRole(state) {
      return state.role;
    },

    areaManagerList(state) {
      return state.areaManagers.map((item) => {
        return {
          id: item.id,
          name: titleCase(item.name)
        }
      });
    },

    areaDSOList(state) {
      return state.areaDSO.map((item) => {
        return {
          id: item.dso.id,
          name: titleCase(item.dso.name)
        };
      });
    },

    brandsList(state) {
      return state.brands.map((item) => {
        return {
          id: item.id,
          name: item.name
        };
      });
    }
  },
  mutations: {
    SET_ROLE(state, value) {
      state.role = value;
    },

    SET_AREA_MANAGER(state, areaManagers) {
      state.areaManagers = areaManagers;
    },

    SET_AREA_DSO(state, areDSO) {
      state.areaDSO = areDSO;
    },

    SET_BRANDS(state, brands) {
      state.brands = brands;
    }
  },
  actions: {
    storeRole({ commit }, role) {
        commit("SET_ROLE", role);
    },

    async getAreaManagers({ commit }) {
      const fetchArea = await am.getAreaManagerAMList(1, 1000, "");
      if(fetchArea) {
        const data = am.areaManagerAMList;
        commit("SET_AREA_MANAGER", data);
      }
    },

    async getAreaDSO({ commit }) {
      const fetchArea = await am.getAreaManagerDSOList(1, 1000, "");
      if(fetchArea) {
        const data = am.areaManagerDSOList;
        commit("SET_AREA_DSO", data);
      }
    },

    async getBrands({ commit }) {
      const fetchBrands = await brand.getBrandList(1, 1000, "");
      if(fetchBrands) {
        const data = brand.brandList;
        commit("SET_BRANDS", data);
      }
    }
  }
});
