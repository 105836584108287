export default{
    namespaced: true,
    state:{
        brand:'',
        categoryCommunity:'',
        status:'',
        dso:'',
    },
    mutations:{
        setdso :(state,payload) => {
            state.dso = payload
            },
        setbrand :(state,payload) => {
            state.brand = payload
            },
        setcategory :(state,payload) => {
            state.categoryCommunity = payload
            },
        setstatus :(state,payload) => {
            state.status = payload
            },
    },
    actions:{
        setdso:({commit},payload)=>{
            commit('setdso',payload)
        },
        setbrand:({commit},payload)=>{
            commit('setbrand',payload)
        },
        setcategory:({commit},payload)=>{
            commit('setcategory',payload)
        },
        setstatus:({commit},payload)=>{
            commit('setstatus',payload)
        },
    },
    getters:{
        dso : state => state.dso,
        brand : state => state.brand,
        categoryCommunity : state => state.categoryCommunity,
        status : state => state.status,
    },
    
}